<template>
  <div class="container content">
    <div class="headline">
      <h1>&nbsp;</h1>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.register-company.thank-you.header') }}</p>
          <div class="columns">
              {{ $t('message.register-company.thank-you.txt') }}
          </div>
        </article>
     </div>
    </div>  
  </div>
</template>

<script>
export default {
}
</script>