var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container content"},[_c('div',{staticClass:"headline"},[_c('h1',[_vm._v(_vm._s(_vm.$t("message.register-company.header")))])]),_c('div',{staticClass:"tile is-ancestor"},[_c('div',{staticClass:"tile is-parent is-4"},[_c('article',{staticClass:"tile is-child box"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("message.register-company.instructions.header"))+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('message.register-company.instructions.txt'))}})])]),_c('div',{staticClass:"tile is-parent is-8"},[_c('article',{staticClass:"tile is-child box"},[_c('p',{staticClass:"title"},[_vm._v(" 1. "+_vm._s(_vm.$t("message.register-company.add.user.header"))+" ")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('b-field',{attrs:{"label":_vm.$t(
                                    'message.register-company.add.user.firstname'
                                )}},[_c('b-input',{staticClass:"cFirstName",model:{value:(_vm.newUser.first_name),callback:function ($$v) {_vm.$set(_vm.newUser, "first_name", $$v)},expression:"newUser.first_name"}})],1),_c('b-field',{attrs:{"label":_vm.$t(
                                    'message.register-company.add.user.lastname'
                                )}},[_c('b-input',{staticClass:"cLastName",model:{value:(_vm.newUser.last_name),callback:function ($$v) {_vm.$set(_vm.newUser, "last_name", $$v)},expression:"newUser.last_name"}})],1),_c('b-field',{attrs:{"label":_vm.$t('message.partner.add.user.email'),"type":_vm.emailFieldType,"message":_vm.emailFieldMessage}},[_c('b-input',{attrs:{"type":"email","value":"","validation-message":_vm.$t('message.error.email-wrong-form')},on:{"blur":_vm.validateEmail},model:{value:(_vm.newUser.email),callback:function ($$v) {_vm.$set(_vm.newUser, "email", $$v)},expression:"newUser.email"}})],1)],1),_c('div',{staticClass:"column is-half"},[_c('b-field',{attrs:{"label":_vm.$t('message.partner.add.user.phone'),"type":_vm.mobileFieldType,"message":_vm.mobileFieldMessage}},[_c('b-input',{on:{"blur":_vm.validateMobile},model:{value:(_vm.newUser.mobile),callback:function ($$v) {_vm.$set(_vm.newUser, "mobile", $$v)},expression:"newUser.mobile"}})],1),_c('b-field',{attrs:{"label":_vm.$t('message.partner.add.user.password')}},[_c('b-input',{attrs:{"type":"password","value":"","password-reveal":"","validation-message":_vm.$t('message.error.password-mismatch')},model:{value:(_vm.newUser.password),callback:function ($$v) {_vm.$set(_vm.newUser, "password", $$v)},expression:"newUser.password"}})],1),_c('password',{attrs:{"strength-meter-only":true},on:{"input":_vm.validateFirstPassword},model:{value:(_vm.newUser.password),callback:function ($$v) {_vm.$set(_vm.newUser, "password", $$v)},expression:"newUser.password"}}),_c('b-field',{attrs:{"label":_vm.$t(
                                    'message.partner.add.user.confirm-password'
                                ),"type":_vm.passwordFieldType,"message":_vm.passwordFieldMessage}},[_c('b-input',{attrs:{"type":"password","value":"","password-reveal":"","validation-message":_vm.$t('message.error.password-mismatch')},on:{"blur":_vm.validatePassword},model:{value:(_vm.newUser.c_password),callback:function ($$v) {_vm.$set(_vm.newUser, "c_password", $$v)},expression:"newUser.c_password"}})],1)],1)]),_c('p',{staticClass:"title"},[_vm._v(" 2. "+_vm._s(_vm.$t("message.register-company.add.company.header"))+" ")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('b-field',{attrs:{"label":_vm.$t(
                                    'message.register-company.add.company.name'
                                )}},[_c('b-input',{staticClass:"cCompanyName",model:{value:(_vm.newCompany.name),callback:function ($$v) {_vm.$set(_vm.newCompany, "name", $$v)},expression:"newCompany.name"}})],1)],1),_c('div',{staticClass:"column is-half"},[_c('b-field',{attrs:{"type":_vm.vatFieldType,"message":_vm.vatFieldMessage,"label":_vm.$t(
                                    'message.register-company.add.company.business-id'
                                )}},[_c('b-input',{staticClass:"cCompanyVat",on:{"blur":_vm.validateVAT},model:{value:(_vm.newCompany.vat),callback:function ($$v) {_vm.$set(_vm.newCompany, "vat", $$v)},expression:"newCompany.vat"}})],1)],1)]),(_vm.isValid && _vm.validator.email && _vm.validator.mobile)?_c('b-button',{staticClass:"fullwidth",attrs:{"loading":false,"label":_vm.$t('message.partner.add.send'),"size":"is-large","type":"is-success"},on:{"click":_vm.register}}):_vm._e(),(!_vm.isValid || !_vm.validator.email || !_vm.validator.mobile)?_c('b-button',{staticClass:"fullwidth",staticStyle:{"cursor":"not-allowed"},attrs:{"loading":false,"label":_vm.$t('message.partner.add.send'),"size":"is-large","type":"is-warning"}}):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }