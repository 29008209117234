<template>
  <div class="dashboard container content">
    <div class="headline"><h1>{{ employee.first_name}} {{ employee.last_name}}</h1></div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-4">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.employee.edit.header') }}</p>
          <b-field :label="$t('message.employees.add.firstname')">
              <b-input v-model="employee.first_name"></b-input>
          </b-field>

           <b-field :label="$t('message.employees.add.lastname')">
              <b-input v-model="employee.last_name"></b-input>
          </b-field>

          <b-field :label="$t('message.employees.add.mobile')">
              <b-input v-model="employee.mobile"></b-input>
          </b-field>

          <b-field :label="$t('message.employees.add.email')">
              <b-input type="email"
                  value=""
                  v-model="employee.email">
              </b-input>
          </b-field>

          <b-field :label="$t('message.users.add.language')">
            <b-select v-model="employee.lang">
                <option value="fi">{{ $t('message.users.languages.fi') }}</option>
                <option value="en">{{ $t('message.users.languages.en') }}</option>
            </b-select>
          </b-field>
          <b-field :label="$t('message.users.add.notify')">
            <b-checkbox v-model="employee.notify"
                :true-value="true"
                :false-value="false">
                {{ checkboxValueToString }}
            </b-checkbox>
          </b-field>

          <b-button
            :label="$t('message.employee.edit.submit')"
            type="is-primary"
              v-on:click="edit_employee" />
        </article>
     </div>
     <div class="tile is-parent is-8">
        <article class="tile is-child box"
        >
                <p class="title">Käyttäjän saldot:</p>
            <div>

            Muutosturva: {{employee.saldo.balance_muutosturva}}<br>
            Työsuhde-etu:{{employee.saldo.balance_tyosuhde_etu}}<br>
            Yhteensä: {{employee.saldo.balance_total}}<br><br>
            
            Vanhentunut muutosturva: {{employee.saldo.expired_muutosturva}}<br>
            Vanhentunut työsuhde-etu: {{employee.saldo.expired_tyosuhde_etu}}<br>
            Vanhentunut yhteensä: {{employee.saldo.expired_total}}<br>
            Seuraava vanhentumispäivä: {{employee.saldo.next_expire}}<br><br>

            Varattu muutosturva: {{employee.saldo.reserved_muutosturva}}<br>
            Varattu työsuhde-etu: {{employee.saldo.reserved_tyosuhde_etu}}<br>
            Varattu yhteensä: {{employee.saldo.reserved_total}}<br><br>

            Käytetty muutosturva muutosturva: {{employee.saldo.used_muutosturva}}<br>
            Käytetty työsuhde-etu: {{employee.saldo.used_tyosuhde_etu}}<br>
            Käytetty yhteensä: {{employee.saldo.used_total}}
            </div>
                <p class="title">Uusi tilitapahtuma</p>
                <div>
                Valitse ostettava koulutus:
                <select>
                    <option v-for="course in courses" :key="course.id">{{course.name}}</option>
                </select>
                <button>Osta</button>
                </div>
            <div></div>
          <p class="title">{{ $t('message.employee.list.transactions.header') }}</p>
           <b-table
                :data="transactions"
                :columns="columns"
                v-on:click="goto_employee_card">
          </b-table>        </article>

     </div>
    </div>    
  </div>
</template>

<script>
import axios from "axios";
export default {
        data() {
            return {
                employee: {},
                courses: [],
                transactions: [
                    {
                        date: "2022-03-18",
                        amount: 100
                    },
                    {
                        date: "2022-03-14",
                        amount: 120
                    },
                    {
                        date: "2022-02-13",
                        amount: 150
                    }
                ],
                columns: [
                    {
                        field: 'date',
                        label: this.$t('message.employee.list.transactions.date')
                    },
                    {
                        field: 'amount',
                        label: this.$t('message.employee.list.transactions.amount')
                    },
                ]
            }
        },

        methods: {
            edit_employee() {
                /* //This needed? 
                console.log("this.user", this.user)
                this.employee.company_id = this.user.company_id
                this.$store.dispatch("createEmployee", this.employee).then((response) => {
                    var status = response.status
                    if (status >= 200 && status < 300) { 
                        this.$store.state.employees.push(response.data.employee)
                        this.toastSuccess(this.$t('message.saldon-tilaus.toaster.success.employee-created', {email: response.data.employee.email}));
                    } else {
                        this.toastFailure(this.$t('message.saldon-tilaus.toaster.error.creating-employee'));
                    }
                }).catch((error) => {
                    this.ERROR(error);
                    this.toastFailure(this.$t('message.saldon-tilaus.toaster.error.creating-employee'));
                });
                 */
            }, 
        },

        computed: {
            checkboxValueToString(){
                if(this.employee.notify) {
                    return this.$t('message.users.add.notify-texts.yes');
                }
                return this.$t('message.users.add.notify-texts.no');
            },
        },
        mounted() {
            axios.get('/users/'+this.$route.params.user_id).then((reponse) => {
                this.employee = reponse.data;
            })
              axios.get('/courses').then((reponse) => {
                this.courses = reponse.data;
            })
        }
    }
</script>

