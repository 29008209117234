<template>
  <div class="container content">
    <div class="headline"><h1>{{ $t('message.partners.header') }}</h1></div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-4">
        <article class="tile is-child box">

            <p class="title">{{ $t('message.partner.add.company.header') }}</p>

            <b-field :label="$t('message.partner.add.company.name')">
                <b-input v-model="newPartner.name"></b-input>
            </b-field>
            <b-field :label="$t('message.partner.add.company.email')">
                <b-input type="email"
                    value=""
                    v-model="newPartner.email">
                </b-input>
            </b-field>
            <b-field :label="$t('message.partner.add.company.phone')">
                <b-input v-model="newPartner.mobile"></b-input>
            </b-field>
            <b-field :label="$t('message.partner.add.company.address')">
                <b-input v-model="newPartner.address"></b-input>
            </b-field>
            <b-field :label="$t('message.partner.add.company.postcode')">
                <b-input v-model="newPartner.zip"></b-input>
            </b-field>
            <b-field :label="$t('message.partner.add.company.city')">
                <b-input v-model="newPartner.city"></b-input>
            </b-field>
            
            <b-field :label="$t('message.partner.add.company.business-id')">
                <b-input v-model="newPartner.vat"></b-input>
            </b-field>
            <b-field :label="$t('message.partner.add.company.homepage')">
                <b-input v-model="newPartner.www"></b-input>
            </b-field>
            
            <label class="label">{{ $t('message.partner.add.company.logo.header') }}</label>
            <b-field class="file is-primary" :class="{'has-name': !!file}">
            <b-upload v-model="newPartner.logo" class="file-label">
                <span class="file-cta">
                    <b-icon class="file-icon" icon="upload"></b-icon>
                    <span class="file-label">{{ $t('message.partner.add.company.logo.load-here') }}</span>
                </span>
                <span class="file-name" v-if="file">
                    {{ file.name }}
                </span>
            </b-upload>
            </b-field>

        <b-button
            :loading="false"
            :label="$t('message.partner.add.send')"
            size="is-large"
            class="fullwidth"
            type="is-success" 
            v-on:click="register" />

        </article>
     </div>
     <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.partners.list.header') }}</p>
           <b-table
                :data="partners"
                :columns="columns">
          </b-table>      
        </article>
     </div>
    </div>    
  </div>
</template>

<script>
export default {
    data() {
        return {
            newPartner: {
                name: '',
                address: '',
                email: '',
                mobile: '',
                zip: '',
                city: '',
                vat: '',
                logo: '',
                www: '',
            },
            columns: [
                {
                    field: 'name',
                    label: this.$t('message.partners.list.name')
                },
            ]
        }
    },
    computed: {
        partners() {
            return this.$store.getters['getPartners']
        }
    },
    mounted() {
        this.$store.dispatch("fetchPartners");
    },
    methods: {
        register() {
            this.$store.dispatch("validatePartner", this.newPartner).then((response) => {
                console.log(response)
                this.$store.dispatch("createPartner", this.newPartner).then((response) => {
                    var status = response.status
                    if (status >= 200 && status < 300) {
                        if (status >= 200 && status < 300) {
                            this.toastSuccess(this.$t('message.partner.toaster.user.created'))
                            this.$store.dispatch("fetchPartners");
                            
                            this.newPartner.name = "";
                            this.newPartner.address = "";
                            this.newPartner.email = "";
                            this.newPartner.mobile = "";
                            this.newPartner.zip = "";
                            this.newPartner.city = "";
                            this.newPartner.vat = "";
                            this.newPartner.logo = {};
                            this.newPartner.www = "";
                            
                        } else {
                            this.toastFailure(this.$t('message.partner.toaster.user.creationError'))
                        }
                    } else {
                        this.toastFailure(this.$t('message.partner.toaster.company.creationError'));
                    }
                }).catch((error) => {
                    this.ERROR(error);
                    this.toastFailure(this.$t('message.partner.toaster.company.creationError'));
                });
            }).catch((error) => {
                var displayed = false
                var msg = error.response.data
                for (const _key in msg.errors) {
                    this.toastFailure(this.$t("message.error.education-partner." + _key));
                    displayed = true
                }
                if (!displayed) {
                    this.toastFailure(this.$t('message.partner.toaster.company.creationError'));
                }
                console.log(error, error.response)
            })

        }
    }
}
</script>

