<template>
  <div class="dashboard container content">
    <div class="headline"><h1>{{ $t('message.employees.header') }}</h1></div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-4">
        <article class="tile is-child box" v-if="user && user.type != 'admin'">
          <p class="title">{{ $t('message.employees.add.header') }}</p>
          <b-field :label="$t('message.employees.add.firstname')">
              <b-input v-model="employee.first_name"></b-input>
          </b-field>

           <b-field :label="$t('message.employees.add.lastname')">
              <b-input v-model="employee.last_name"></b-input>
          </b-field>

          <b-field :label="$t('message.employees.add.mobile')">
              <b-input v-model="employee.mobile"></b-input>
          </b-field>

          <b-field :label="$t('message.employees.add.email')">
              <b-input type="email"
                  value=""
                  v-model="employee.email">
              </b-input>
          </b-field>

          <b-field :label="$t('message.users.add.language')">
            <b-select v-model="employee.lang">
                <option value="fi">{{ $t('message.users.languages.fi') }}</option>
                <option value="en">{{ $t('message.users.languages.en') }}</option>
            </b-select>
          </b-field>
            Lisätylle työntekijälle ei lähde notifikaatiota. 
          <b-button
            :label="$t('message.employees.add.submit')"
            type="is-primary"
              v-on:click="add_employee" />
        </article>
     </div>
     <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.employees.list.header') }}</p>
           <b-table
                class="hoverable"
                :data="employees"
                :columns="columns"
                v-on:click="goto_employee_card">
          </b-table>      
        </article>
     </div>
    </div>    
  </div>
</template>

<script>
export default {
        data() {
            return {
                employee: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    mobile: '',
                    company_id: 0,
                    notify: false, 
                    lang: 'fi'
                },
                columns: [
                    {
                        field: 'first_name',
                        label: this.$t('message.employees.list.firstname')
                    },
                    {
                        field: 'last_name',
                        label: this.$t('message.employees.list.lastname')
                    },
                    {
                        field: 'email',
                        label: this.$t('message.employees.list.email')
                    },
                    {
                        field: 'mobile',
                        label: this.$t('message.employees.list.mobile'),
                    }
                ]
            }
        },

        methods: {
            goto_employee_card(item) {
                console.log("going to employee card", item.first_name)
                this.$router.push('/tyontekija/' + item.id)
            },
            add_employee() {
                console.log("this.user", this.user)
                this.employee.company_id = this.user.company_id
                this.$store.dispatch("createEmployee", this.employee).then((response) => {
                    var status = response.status
                    if (status >= 200 && status < 300) { 
                        this.$store.state.employees.push(response.data.employee)
                        this.toastSuccess(this.$t('message.saldon-tilaus.toaster.success.employee-created', {email: response.data.employee.email}));
                    } else {
                        this.toastFailure(this.$t('message.saldon-tilaus.toaster.error.creating-employee'));
                    }
                }).catch((error) => {
                    this.ERROR(error);
                    this.toastFailure(this.$t('message.saldon-tilaus.toaster.error.creating-employee'));
                });
            }
        },

        computed: {
            checkboxValueToString(){
                if(this.employee.notify) {
                    return this.$t('message.users.add.notify-texts.yes');
                }
                return this.$t('message.users.add.notify-texts.no');
            },
            employees() {
                return this.$store.getters['getEmployees']
            },
            user() {
                return this.$store.getters['getUser']
            },
        },
        mounted() {
            this.$store.dispatch("fetchEmployees");
        }
    }
</script>

