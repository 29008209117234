<template>
    <div class="container content">
        <div class="headline">
            <h1>{{ $t("message.dashboard.header") }}</h1>
        </div>
        <div class="tile is-ancestor" v-if="activeUser.type == 'enduser'">
            <div class="tile is-parent is-4">
                <article class="tile is-child box">
                    <p class="title">Omat koulutussetelit</p>
                    <p class="summa">{{ activeUser.saldo.balance_total }}€</p>
                    <p>
                        <b>Työsuhde-etu:</b>
                        {{ activeUser.saldo.balance_tyosuhde_etu }} €<br /><b
                            >Muutosturva:</b
                        >
                        {{ activeUser.saldo.balance_muutosturva }} €
                    </p>
                </article>
            </div>
            <div class="tile is-parent is-4">
                <article class="tile is-child box">
                    <p class="title">Käytetyt koulutussetelit</p>
                    <p class="summa">{{ activeUser.saldo.used_total }}€</p>
                    <p>
                        <b>Työsuhde-etu:</b>
                        {{ activeUser.saldo.used_tyosuhde_etu }} €<br /><b
                            >Muutosturva:</b
                        >
                        {{ activeUser.saldo.used_muutosturva }} €
                    </p>
                </article>
            </div>
            <div class="tile is-parent is-4">
                <article class="tile is-child box">
                    <p class="title">Varatut koulutussetelit</p>
                    <p class="summa">{{ activeUser.saldo.reserved_total }}€</p>
                    <p>
                        <b>Työsuhde-etu:</b>
                        {{ activeUser.saldo.reserved_tyosuhde_etu }} €<br /><b
                            >Muutosturva:</b
                        >
                        {{ activeUser.saldo.reserved_muutosturva }} €
                    </p>
                </article>
            </div>
        </div>
     


    </div>
</template>

<script>
export default {
    data() {
        return {};
    },

    computed: {
        user() {
            return this.$store.getters["getUser"];
        },
        newOrders() {
            return this.set_company(this.$store.getters["getNewOrders"]);
        },
        billedOrders() {
            return this.set_company(this.$store.getters["getBilledOrders"]);
        },
        paidOrders() {
            return this.set_company(this.$store.getters["getPaidOrders"]);
        },
    },
    methods: {
        set_company(orders) {
            var companies = this.$store.getters["getCompanies"];
            orders.forEach(async function (order) {
                order.company = companies.find(
                    (company) => company.id == order.company_id
                );
            });
            return orders;
        },
        move_order_to(id, _status) {
            this.$store
                .dispatch("changeOrderStatus", { id: id, status: _status })
                .then((response) => {
                    var status = response.status;
                    if (status >= 200 && status < 300) {
                        this.toastSuccess(
                            this.$t(
                                "message.dashboard.order.moved_to." +
                                    _status +
                                    ".success"
                            )
                        );

                        this.$store.dispatch("fetchNewOrders");
                        this.$store.dispatch("fetchBilledOrders");
                        this.$store.dispatch("fetchPaidOrders");
                    } else {
                        this.toastFailure(
                            this.$t(
                                "message.dashboard.order.moved_to." +
                                    _status +
                                    ".error"
                            )
                        );
                    }
                })
                .catch((error) => {
                    this.ERROR(error);
                    this.toastFailure(
                        this.$t(
                            "message.dashboard.order.moved_to." +
                                _status +
                                ".error"
                        )
                    );
                });
        },
    },
    mounted() {
        if (this.user && this.user.type == "admin") {
            this.$store.dispatch("fetchNewOrders");
            this.$store.dispatch("fetchBilledOrders");
            this.$store.dispatch("fetchPaidOrders");
            this.$store.dispatch("fetchCompanies");
        }
    },
};
</script>

