<template>
  <div class="dashboard container content">
    <div class="headline"><h1>{{ $t('message.users.header') }} asdfasdf</h1></div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-4">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.users.add.header') }}</p>
          <b-field :label="$t('message.users.add.firstname')">
              <b-input v-model="newUser.first_name"></b-input>
          </b-field>

           <b-field :label="$t('message.users.add.lastname')">
              <b-input v-model="newUser.last_name"></b-input>
          </b-field>

           <b-field :label="$t('message.users.add.mobile')">
              <b-input v-model="newUser.mobile"></b-input>
          </b-field>
          
          <b-field :label="$t('message.users.add.company')" v-if="user.type == 'admin'">
            <b-autocomplete
                @typing="(txt) => updateSuggestedCompanies(txt)"
                :keep-first="false"
                :open-on-focus="false"
                :data="suggest_company"
                field="name"

                :placeholder="$t('message.users.add.company-suggest')" 
                @select="option => company = option" />
          </b-field>

          <b-field :label="$t('message.users.add.partner')"  v-if="user.type == 'admin'">
            <b-autocomplete
                @typing="(txt) => updateSuggestedPartners(txt)"
                :keep-first="false"
                :open-on-focus="false"
                :data="suggest_partner"
                field="name"

                :placeholder="$t('message.users.add.partner-suggest')" 
                @select="option => partner = option" />
          </b-field>1

          <b-field :label="$t('message.users.add.email')">
              <b-input type="email"
                  value=""
                  v-model="newUser.email"
                  maxlength="30">
              </b-input>
          </b-field>

          <b-field :label="$t('message.users.add.password')">
            <b-input type="password"
                value=""
                v-model="newUser.password"
                password-reveal>
            </b-input>
          </b-field>
        
          <b-field :label="$t('message.users.add.confirm-password')">
            <b-input type="password"
                value=""
                v-model="newUser.c_password"
                password-reveal>
            </b-input>
          </b-field>

          <b-field :label="$t('message.users.add.language')">
            <b-select v-model="newUser.lang">
                <option value="fi">{{ $t('message.users.languages.fi') }}</option>
                <option value="en">{{ $t('message.users.languages.en') }}</option>
            </b-select>
          </b-field>
          <b-field :label="$t('message.users.add.notify')">
            <b-checkbox v-model="newUser.notify"
                :true-value="true"
                :false-value="false">
                {{ checkboxValueToString }}
            </b-checkbox>
          </b-field>

          <b-button
            :label="$t('message.users.add.submit')"
            type="is-primary"
            v-on:click="register"  />
        </article>
     </div>
     <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.users.list.header') }} </p>
           <b-table
                v-on:click="openUserCard"
                :data="users">

                <b-table-column field="first_name" :label="$t('message.users.list.first_name')" width="40" sortable v-slot="props">
                    {{props.row.first_name}}
                </b-table-column>

                <b-table-column field="last_name" :label="$t('message.users.list.last_name')" width="40" sortable v-slot="props">
                    {{props.row.last_name}}
                </b-table-column>
                
                <b-table-column field="email" :label="$t('message.users.list.email')" width="40" sortable v-slot="props">
                    {{props.row.email}}
                </b-table-column>

                <b-table-column field="type" :label="$t('message.users.list.type.header')" width="40" sortable v-slot="props">
                    {{$t('message.users.list.type.' + props.row.type)}}
                </b-table-column>

                <b-table-column field="org" :label="$t('message.users.list.organization')" width="40" sortable v-slot="props">
                    <span v-if="'org' in props.row">
                        {{props.row.org.name}}
                    </span>
                </b-table-column>
                
          </b-table>
        </article>
     </div>
    </div>    
  </div>
</template>

<script>
export default {
        data() {
            return {
                company: null,
                suggest_company: [],

                partner: null,
                suggest_partner: [],

                newUser: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    mobile: '',
                    password: '',
                    c_password: '',
                    lang: 'fi',
                    company_id: -1,
                    company_uuid: null,
                    type: 'company', 
                    notify: false, 
                },
            }
        },
        computed: {
            checkboxValueToString(){
                if(this.newUser.notify) {
                    return this.$t('message.users.add.notify-texts.yes');
                }
                return this.$t('message.users.add.notify-texts.no');
            },
            user() {
                return this.$store.getters['getUser']
            },
            users() {
                var partners = this.$store.getters['getPartners']
                var companies = this.$store.getters['getCompanies']
                var users = this.$store.getters['getUsers']
                users.forEach(user => {
                    if (user.company_uuid != null) {
                        var org = companies.find(company => company.uuid == user.company_uuid)
                        if (org != undefined) {
                            user.org = org
                        }
                    }
                    if (user.education_partner_id != null) {
                        var org2 = partners.find(partner => partner.uuid == user.education_partner_uuid)
                        if (org2 != undefined) {
                            user.org = org2
                        }
                    }
                });
                return users
            }, 
            companies() {
                return this.$store.getters['getCompanies']
            }, 
            partners() {
                return this.$store.getters['getPartners']
            }
        },
        methods: {
            openUserCard(item) {
                this.$router.push('/kayttaja/' + item.id)
            },
            updateSuggestedCompanies(txt) {
                var ff = this.companies.filter(comp => {
                    return (
                        comp.name
                            .toString()
                            .toLowerCase()
                            .indexOf(txt.toLowerCase()) >= 0
                    )
                })
                this.suggest_company = ff
            },
            updateSuggestedPartners(txt) {
                var ff = this.partners.filter(comp => {
                    return (
                        comp.name
                            .toString()
                            .toLowerCase()
                            .indexOf(txt.toLowerCase()) >= 0
                    )
                })
                this.suggest_partner = ff
            },
            register() {
                if (this.user.type == "admin") {
                    console.log(this.company)
                    if (this.company != null) {
                        this.newUser.type = "company"
                        this.newUser.company_uuid = this.company.uuid
                    }
                    console.log(this.partner)
                    if (this.partner != null) {
                        this.newUser.type = "education_partner"
                        this.newUser.education_partner_uuid = this.partner.uuid
                    }
                } else {
                    //console.log("companies", this.companies)
                    if (this.user.type == "company") {
                        let companyUuid = ""
                        this.companies.forEach(comp => {
                            if (comp.id == this.user.company_id) {
                                companyUuid = comp.uuid
                            }
                        });
                        this.newUser.company_id = this.user.company_id
                        this.newUser.company_uuid = companyUuid
                        this.newUser.type = "company"
                        //console.log("new user", this.newUser, this.user)
                    }
                    if (this.user.type == "education_partner") {
                        var partnerUuid = ""
                        this.partners.forEach(partner => {
                            if (partner.id == this.user.partner_id) {
                                partnerUuid = partner.uuid
                            }
                        });
                        this.newUser.type = "education_partner"
                        this.newUser.education_partner_id = this.partner.id
                        this.newUser.education_partner_uuid = partnerUuid
                        //console.log("new user", this.newUser, this.user)
                    }
                }
            
                this.$store.dispatch("createUser", this.newUser).then((response) => {
                    var status = response.status
                    if (status >= 200 && status < 300) {
                        this.toastSuccess(this.$t('message.users.add.toaster.user.created'))
                        this.$store.dispatch("fetchUsers");
                    } else {
                        this.toastFailure(this.$t('message.users.add.toaster.user.creationError'));
                    }
                }).catch((error) => {
                    this.ERROR(error);
                    this.toastFailure(this.$t('message.users.add.toaster.user.creationError'));
                });
            }
        },
        mounted() {
            this.$store.dispatch("fetchPartners");
            this.$store.dispatch("fetchCompanies");
            this.$store.dispatch("fetchUsers");
        }
    }
</script>

